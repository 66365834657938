import React from 'react';
import inEU from '@segment/in-eu';
import { withStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, Grow, Typography, FormControl, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import { AnalyticsConsentStatuses, setAnalyticsConsentStatus } from '../../../utilities/analyticsUtilities';

const styles = theme => ({
});

class ConsentDialog extends React.Component {
    state = {
        consentStatus: AnalyticsConsentStatuses.UNTRACKED
    }

    render() {
        const { open } = this.props;
        const { consentStatus } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                maxWidth='xs'
                fullWidth={true}
                aria-labelledby='consent-dialog-title'
                TransitionComponent={Grow}
            >
                <DialogTitle id='consent-dialog-title'>
                    Data Collection Preferences
                </DialogTitle>
                <DialogContent>
                    <Typography
                        variant='body2'
                    >
                        We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, and increase the overall performance of our site.
                    </Typography>
                    <br/>
                    <FormControl component='fieldset'>
                        <FormLabel component='legend'>Do you allow us to collect analytics data about your usage of Specterr using Google Analytics and Amplitude?</FormLabel>
                        <RadioGroup aria-label='consent status' name='consentStatus' value={consentStatus} onChange={this.handleConsentStatusChange}>
                            <FormControlLabel value={AnalyticsConsentStatuses.OPTIN} control={<Radio />} label='Yes' />
                            <FormControlLabel value={AnalyticsConsentStatuses.OPTOUT} control={<Radio />} label='No' />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleClose}
                    >
                        Close
                    </Button>
                    <div style={{flex: '1 0 0'}} />
                    <Button
                        onClick={() => this.handleSave()}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleClose = () => {
        const { onClose } = this.props;

        // close without preferences saved
        onClose(false);
    };

    handleSave = () => {
        const { onClose } = this.props;
        const { consentStatus } = this.state;

        setAnalyticsConsentStatus(consentStatus, inEU())

        // close with preferences saved
        onClose(true);
    }

    handleConsentStatusChange = (event) => {
        this.setState({consentStatus: event.target.value});
    }
}

export default withStyles(styles)(ConsentDialog);