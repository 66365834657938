
export function executeCarefully(func, ...args) {
    if (func !== undefined && typeof func === 'function') {
        func(...args);
    }
}

export function collectionHelper(elements) {
    return {
        firstOrDefault: function (condition) {
            if (elements) {
                if (condition === undefined) {
                    return elements[0];
                }

                var foundItems = elements.filter(condition);
                if (foundItems) {
                    return foundItems[0];
                }
            }
        },
        getWhere: function (condition) {
            var filteredItems = elements ? elements.filter(condition) : [];
            return filteredItems;
        },
        updateWhere: function (condition) {
            return {
                set: function (setter) {
                    if (elements) {
                        for (var i = 0; i < elements.length; i++) {
                            var current = elements[i];
                            if (condition(current)) {
                                setter(elements[i]);
                            }
                        }
                    }
                    return elements;
                }
            };
        },
        indexOf: function (condition) {
            if (elements) {
                var filteredItems = elements.filter(condition);
                if (filteredItems) {
                    return elements.indexOf(filteredItems[0]);
                }
            }
            return -1;
        }
    };
};