import React from 'react';
import MenuIcon from '@material-ui/icons/MenuRounded';
import { withStyles, AppBar, Toolbar, IconButton, Button, Grid, Typography, Hidden, Menu, MenuItem } from '@material-ui/core';
import Link from './Link';
import { red, grey } from '@material-ui/core/colors';
import { trackLink, AnalyticsConstants } from '../../../utilities/analyticsUtilities';
import { getConfigurationProperty } from '../../../configuration';

const styles = theme => ({
    appBar: {
        backgroundColor: theme.palette.background.darker2,
    },
    mobileSignInButton : {
        marginRight: "15px",
        [theme.breakpoints.up("md")]: {
            display: 'none'
        }
    },
    toolbar: {
        maxWidth: theme.breakpoints.values.lg,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    logoLink: {
        transition: 'height 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
            height: theme.spacing(5) + 4
        },
        height: theme.spacing(5),
        margin: theme.spacing(1, 0),
    },
    logo: {
        height: '100%',
    },
    link: {
        color: theme.palette.text.secondary,
        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
            textDecoration: 'none',
            color: 'white',
            borderBottom: `2px solid ${grey['A200']}`
        },
        textDecoration: 'none'
    },
    activeLink: {
        color: 'white',
        borderBottom: `2px solid ${red['A200']}`,
        '&:hover': {
            borderBottom: `2px solid ${red['A200']}`
        },
    }
});

const signInButtonId = 'navbar-sign-in-button';

const SignInButton = <Button
    variant='outlined'
    href={getConfigurationProperty('VideosListEndpoint')}
    id={signInButtonId}
>
    Sign In
</Button>;

class NavBar extends React.Component {
    state = {
        menuAnchor: null
    }

	render() {
        const { classes } = this.props;
        const { menuAnchor } = this.state;
        
		return (
            <AppBar 
                className={classes.appBar}
                position='fixed'
            >
                <Toolbar 
                    variant='dense'
                    className={classes.toolbar}
                >
                    <Link
                        to='/'
                        className={classes.logoLink}
                    >
                        <img 
                            className={classes.logo} 
                            src='/images/specterr-logo.svg'
                            alt='Specterr logo'
                        />
                    </Link>
                    <div style={{flexGrow: 1, height: '100%'}} />
                    <Hidden smDown>
                        <Grid
                            container
                            justify='flex-end'
                            alignItems='center'
                            spacing={5}
                        >
                            <Grid item>
                                {NavLink('/', 'Home', classes)}
                            </Grid>
                            <Grid item>
                                {NavLink('/pricing/', 'Pricing', classes)}
                            </Grid>
                            <Grid item>
                                {NavLink('/music-visualizer/', 'Visualizers', classes)}
                            </Grid>
                            <Grid item>
                                {NavLink('/lyric-video-maker/', 'Lyric Videos', classes)}
                            </Grid>
                            <Grid item>
                                {SignInButton}
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Grid item className={classes.mobileSignInButton}>
                        {SignInButton}
                    </Grid>
                    <Hidden mdUp>
                        <IconButton 
                            edge='start' 
                            color='inherit' 
                            aria-label='menu'
                            onClick={e => this.setState({menuAnchor: e.currentTarget})}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <Menu
                        keepMounted
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={() => this.setState({menuAnchor: null})}
                    >
                        <MenuItem>
                            {NavLink('/', 'Home', classes)}
                        </MenuItem>
                        <MenuItem>
                            {NavLink('/pricing/', 'Pricing', classes)}
                        </MenuItem>
                        <MenuItem>
                            {NavLink('/music-visualizer/', 'Visualizers', classes)}
                        </MenuItem>
                        <MenuItem>
                            {NavLink('/lyric-video-maker/', 'Lyric Videos', classes)}
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
		);
    }
    
    componentDidMount() {
		setTimeout(() => {
			trackLink(signInButtonId, 'Clicked Sign In', {
				category: AnalyticsConstants.ACCOUNT_CATEGORY
			});
		}, 100);
	}
}

export default withStyles(styles)(NavBar);

function NavLink(path, text, classes) {
    return(
        <Link
            to={path}
            activeClassName={classes.activeLink}
            className={classes.link}
        >
            <Typography variant='button'>
                {text}
            </Typography>
        </Link>
    )
}