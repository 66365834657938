import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    backdrop: {
        position: 'absolute',
        height: '100%',
        width: '100vw',
        zIndex: -1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover'
    }
});

class SolidBackDrop extends React.Component {
	render() {
		const { classes, color, image, shadow, border } = this.props;

		return (
            <span
                className={classes.backdrop}
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundColor: color,
                    boxShadow: shadow ? '0 0px 20px 0px rgba(0, 0, 0, 1)' : 'none',
                    borderTop: border ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'
                }}
            />
		);
	}
}

export default withStyles(styles)(SolidBackDrop);