import React, { useEffect, useState } from 'react';

const UseIsSsr = () => {
    const [isSsr, setIsSsr] = useState(true);

    useEffect(() => {
        setIsSsr(false);
    }, []);

    return isSsr;
};

export const withSsr = (WrappedComponent) => {
    return function withSsr(props) {
        const isSsr = UseIsSsr();
        return <WrappedComponent {...props} isSsr={isSsr} />;
    };
};