import { isSSG } from "./ssrUtilities";
import { allFirstToUpper } from './stringUtilities';
import inEU from '@segment/in-eu';
import Cookies from 'js-cookie';
import Plausible from 'plausible-tracker';
import { getConfigurationProperty } from "../configuration";

const plausible = Plausible({ domain: getConfigurationProperty("PlausibleDataDomain") });

export function loadAnalytics() {
    safeCall(() => {
        window.amplitude.getInstance().init(window._analyticsKeys.amplitude);
    }, 1000);
}

export function trackPage(name) {
    safeCall(() => {
        plausible.trackPageview(name);
        
        const uppercaseName = allFirstToUpper((name === '/' ? '/home' : name).split('/')[1].replace('-', ' '));
        window.amplitude.getInstance().logEvent(`Viewed ${uppercaseName} Page`, {
            category: 'Web App',
            name: uppercaseName,
            path: name,
            referrer: document.referrer,
            title: document.title,
            url: document.location.href
        });
    }, 1000);
}

export function trackEvent(name, properties) {
    safeCall(() => {
        plausible.trackEvent(name, { props: properties });
        window.amplitude.getInstance().logEvent(name, properties);
    }, 1000);
}

export function trackLink(id, name, properties) {
    safeCall(() => {
        const link = document.getElementById(id);
        if (link === null)
            return;

        document.getElementById(id).onclick = () => {
            window.amplitude.getInstance().logEvent(name, properties);
        }
    }, 1000);
}

export function loadAnalyticsConsent() {
    if (isSSG())
        return;
     
    // fetch existing consent status cookie
    var analyticsConsentStatus = Cookies.get(ANALYTICS_CONSENT_STATUS_COOKIE_KEY);

    // if there is no consent status cookie, it must be assigned
    if (typeof analyticsConsentStatus === 'undefined') {

        // fetch existing segment consent status cookie
        const segmentTrackingPreferences = Cookies.get('tracking-preferences');
        
        // if the segment cookie doesn't exist set the new consent status to untracked
        // if it does exist, then use that for the consent status
        if (typeof segmentTrackingPreferences === 'undefined') {
            analyticsConsentStatus = AnalyticsConsentStatuses.UNTRACKED;
        } else {
            const segmentTrackingPreferencesObject = JSON.parse(segmentTrackingPreferences);
            analyticsConsentStatus = (segmentTrackingPreferencesObject.destinations["Amplitude"] && segmentTrackingPreferencesObject.destinations["Google Analytics"]) ? AnalyticsConsentStatuses.OPTIN : AnalyticsConsentStatuses.OPTOUT;
        }
    }

    // apply consent status
    const isEUUser = inEU();
    setAnalyticsConsentStatus(analyticsConsentStatus, isEUUser);

    // return wether or not the consent dialog should be displayed
    return (analyticsConsentStatus === AnalyticsConsentStatuses.UNTRACKED && isEUUser);
}

export function setAnalyticsConsentStatus(status, isEUUser) {
    // set cookie on root domain
    const cookieDomain = window.location.host === 'specterr.com' ? 'specterr.com' : null;
    Cookies.set(
        ANALYTICS_CONSENT_STATUS_COOKIE_KEY, 
        status, 
        { expires: 365, domain: cookieDomain }
    );

    // if user opted out, or they are in eu and haven't supplied consent, disable analytics transmission
    if (status === AnalyticsConsentStatuses.OPTOUT || (status === AnalyticsConsentStatuses.UNTRACKED && isEUUser)) {
        window.amplitude.getInstance().setOptOut(true);
    } else {
        window.amplitude.getInstance().setOptOut(false);
    }
}

function safeCall(action, waitDuration) {
    if (isSSG())
        return;

    if (window.amplitude && window.ga) {
        action()
    } else {
        setTimeout(() => {
            action();
        }, waitDuration);
    }
}

export const AnalyticsConstants = {
    PERFORMANCE_CATEGORY: 'Performance',
    MISC_CATEGORY: 'Misc',
    ACCOUNT_CATEGORY: 'Account',
    VIDEO_CATEGORY: 'Video',
    ACQUISITION_CATEGORY: 'Acquisition',
    ECOMMERCE_CATEGORY: 'Ecommerce',
}

const ANALYTICS_CONSENT_STATUS_COOKIE_KEY = 'ANALYTICS_CONSENT_STATUS';

export const AnalyticsConsentStatuses = {
    UNTRACKED: 'Untracked',
    OPTIN: 'Optin',
    OPTOUT: 'Optout'
}