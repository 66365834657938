import { red, green, yellow, amber, purple } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: red['A200'],
        },
        secondary: {
            main: green['A400'],
        },
        highlight: {
            green: green['A100'],
            yellow: yellow['100'],
            red: red['100'],
        },
        tint: {
            success: green[700],
            fail: red[700]
        },
        warning: {
            main: yellow[700]
        },
        banner: {
            info: amber[200]
        },
        exportTag: {
            free: yellow['A700'],
            test: purple['A100']
        },
        background: {
            default: '#202124',
            paper: '#313235',
            darker1: '#18181C',
            darker2: '#121414',
        }
    },
    typography: {
        useNextVariants: true,
    },
    shape: {
        borderRadius: 6
    },
    drawer: {
        openWidth: 200,
        closedWidth: 57,
    },
    videoDrawer: {
        width: 300,
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "13px",
            }
        },
        MuiButton: {
            outlined: {
                background: 'rgba(0, 0, 0, 0.2)'
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: red['700'],
                '&:hover': {
                    backgroundColor: '#932020'
                }
            },
            containedSecondary: {
                color: '#212121'
            }
        },
        MuiPaper: {
            rounded: {
                border: '1px solid rgba(255, 255, 255, 0.12)'
            },
            root: {
                overflow: 'hidden'
            }
        },
        MuiCard: {
            root: {
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                '&:hover': {
                    border: '1px solid rgba(255, 255, 255, 0.5)'
                }
            }
        },
    },
});

export default theme;
