import PricingConstants from '../src/components/pricing/PricingConstants';

export function getCurrencyDisplayName(currency) {
    switch(currency) {
        case PricingConstants.CURRENCIES.USD: 
            return 'USD'; 
        case PricingConstants.CURRENCIES.EUR: 
            return 'EUR'; 
        case PricingConstants.CURRENCIES.GBP: 
            return 'GBP'; 
    }
    return '';
}

export function getCurrencySymbol(currency) {
    switch(currency) {
        case PricingConstants.CURRENCIES.USD: 
            return '$'; 
        case PricingConstants.CURRENCIES.EUR: 
            return '€'; 
        case PricingConstants.CURRENCIES.GBP: 
            return '£'; 
    }
    return '';
}

export function getPurchaseOptionDisplayName(planType, purchaseOptionName) {
    switch(planType) {
        case PricingConstants.PLAN_TYPES.UNLIMITED: 
            return 'Unlimited'; 
        case PricingConstants.PLAN_TYPES.PRO: 
            return 'Pro'; 
    }
    return purchaseOptionName;
}

export function allFirstToUpper(input) {
    return input.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}