
// Available environments

const Environments = {
    Local: 'Local',
    Dev: 'Dev',
    Production: 'Production',
};

// The main configuration object with properties

const Configuration = {
    Local: {
        PlausibleDataDomain: "",
        ApplicationHost: 'https://localhost:44321',
        PurchaseOptionsEndpoint: 'https://localhost:44321/api/plan/GetPurchaseOptions',
        CreateVideoEndpoint: 'https://localhost:44321/create',
        VideosListEndpoint: 'https://localhost:44321/videos',
        PlansEndpoint: 'https://localhost:44321/plans/p',
        SupportEndpoint:'https://localhost:44321/support'
    },
    Dev: {
        PlausibleDataDomain: "specterrstaticdev.z21.web.core.windows.net",
        ApplicationHost: 'https://vizfly-dev.azurewebsites.net',
        PurchaseOptionsEndpoint: 'https://vizfly-dev.azurewebsites.net/api/plan/GetPurchaseOptions',
        CreateVideoEndpoint: 'https://vizfly-dev.azurewebsites.net/create',
        VideosListEndpoint: 'https://vizfly-dev.azurewebsites.net/videos',
        PlansEndpoint: 'https://vizfly-dev.azurewebsites.net/plans/p',
        SupportEndpoint: 'https://vizfly-dev.azurewebsites.net/support'
    },
    Production: {
        PlausibleDataDomain: "specterr.com",
        ApplicationHost: 'https://app.specterr.com',
        PurchaseOptionsEndpoint: 'https://app.specterr.com/api/plan/GetPurchaseOptions',
        CreateVideoEndpoint: 'https://app.specterr.com/create',
        VideosListEndpoint: 'https://app.specterr.com/videos',
        PlansEndpoint: 'https://app.specterr.com/plans/p',
        SupportEndpoint: 'https://app.specterr.com/support'
    }
};

// Single function to get the configuration property

export function isProductionHost() {
    return typeof window !== 'undefined' && window.location.host === 'specterr.com';    
}

export function getDomain() {
    if (typeof window !== 'undefined') {
        return window.location.host;
    }
}

export function getConfigurationProperty(key) {
    if (typeof window !== 'undefined') {
        let env = null;

        switch (window.location.host) {
            case "specterr.com":
                env = Environments.Production;
                break;
            case "specterrstaticdev.z21.web.core.windows.net":
                env = Environments.Dev;
                break;
            default:
                env = Environments.Local;
        }

        return Configuration[env][key];
    } 
};