import { executeCarefully } from './basicUtilities';
import { getConfigurationProperty } from '../configuration';

export function httpGetRequest(url, success, fail) {
    var httpRequest = new XMLHttpRequest();

    httpRequest.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
            let data = JSON.parse(httpRequest.responseText);
            executeCarefully(success, data);
        } else {
            executeCarefully(fail);
        }
    };

    httpRequest.open('GET', url, true);
    httpRequest.send();
};

export function loadPurchaseOptions(success, fail) {
    let url = getConfigurationProperty('PurchaseOptionsEndpoint');

    httpGetRequest(url, (response) => {
        executeCarefully(success, response);
    }, () => {
        executeCarefully(fail);
    });
};