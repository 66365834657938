export const CURRENCY_STORAGE_KEY = 'RECOMMENDED_CURRENCY';
export const PURCHASE_OPTIONS_STORAGE_KEY = 'PURCHASE_OPTIONS_1';
export const PRICING_VIDEO_QUALITY_STORAGE_KEY = 'PRICING_PRO_PLAN_VIDEO_QUALITY';
export const PRICING_PERIOD_TYPE_STORAGE_KEY = 'PRICING_PERIOD_TYPE';

export function getCookieValue(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

export function getCurrencyFromStorage() {
    if (typeof localStorage !== 'undefined') {
        let currency = localStorage.getItem(CURRENCY_STORAGE_KEY);
        return currency ? parseInt(currency) : undefined;
    }
}

export function saveCurrencyToStorage(currency) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem(CURRENCY_STORAGE_KEY, currency);
    }
}

export function getPurchaseOptionsFromStorage() {
    if (typeof localStorage !== 'undefined') {
        let purchaseOptions = localStorage.getItem(PURCHASE_OPTIONS_STORAGE_KEY);
        return JSON.parse(purchaseOptions);
    }
}

export function savePurchaseOptionsToStorage(purchaseOptions) {
    if (typeof localStorage !== 'undefined') {
        let data = JSON.stringify(purchaseOptions);
        localStorage.setItem(PURCHASE_OPTIONS_STORAGE_KEY, data);
    }
}
