import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import theme from '../../src/theme';
import { loadAnalytics, loadAnalyticsConsent } from '../../utilities/analyticsUtilities';
import NavBar from '../../src/components/misc/NavBar';
import Footer from '../../src/components/misc/Footer';
import { savePurchaseOptionsToStorage } from '../../utilities/storageUtilities';
import { loadPurchaseOptions } from '../../utilities/restApiUtilities';
import ConsentPopup from '../../src/components/misc/ConsentPopup';
import { isSSG } from '../../utilities/ssrUtilities';

export default class TopLayout extends React.Component {
	state = {
        showConsentPopup: false
	}

	render() {
		const { children } = this.props;
		const { showConsentPopup } = this.state

		if (isSSG()) 
			return null;
		
		return (
			<React.Fragment>
				<Helmet>
					<meta
						name='viewport'
						content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
					/>
					<link rel="icon" href="https://specterr.b-cdn.net/specterr-favicon-32.png" sizes="32x32" />
					<link rel="icon" href="https://specterr.b-cdn.net/specterr-favicon-192.png" sizes="192x192" />
					<style type="text/css">{`
						body {
							background: url(https://specterr.b-cdn.net/dots.png);
							background-size: contain;
							background-color: #202124;
						}
						html, body {
							max-width: 100%;
							overflow-x: hidden;
						}
					`}</style>
					<script>
						{`
							const isProd = window.location.host === 'specterr.com';
							window._analyticsKeys = {
								google: isProd ? 'UA-152625290-2' : 'UA-152625290-1',
								amplitude: isProd ? '932738cbfd8f8434be5a12759db0c078' : 'b0b4de1b02027232c9b779ca152d274f',
								plausibleProxyLink: isProd ? 'https://crimson-cloud-123e.oliver1861.workers.dev' : 'https://fragrant-fog-c12e.oliver1861.workers.dev'
							}
						`}
					</script>
					<script>
						{`
							const plausibleProxyLink = window._analyticsKeys.plausibleProxyLink;
							const plausibleScript = document.createElement('script');
							plausibleScript.setAttribute("data-domain", window.location.host);
							plausibleScript.setAttribute("data-api", plausibleProxyLink+'/ripa/event');
							plausibleScript.src = plausibleProxyLink+'/rsj/script.js';
							plausibleScript.defer = true;
							document.getElementsByTagName("head")[0].appendChild(plausibleScript);
						`}
					</script>

					<script>
						{`
							(function(e,t){var r=e.amplitude||{_q:[],_iq:{}};var n=t.createElement("script")
							;n.type="text/javascript"
							;n.integrity="sha384-4rr7CTymHc64YjTTL6O3ktfsHYI1yJnQdmKv4zFoe+frjXb05MfzzuLLIAgJ/XHs"
							;n.crossOrigin="anonymous";n.async=true
							;n.src="https://cdn.amplitude.com/libs/amplitude-8.11.0-min.gz.js"
							;n.onload=function(){if(!e.amplitude.runQueuedFunctions){
							console.log("[Amplitude] Error: could not load SDK")}}
							;var s=t.getElementsByTagName("script")[0];s.parentNode.insertBefore(n,s)
							;function i(e,t){e.prototype[t]=function(){
							this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
							var o=function(){this._q=[];return this}
							;var a=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove"]
							;for(var c=0;c<a.length;c++){i(o,a[c])}r.Identify=o;var u=function(){this._q=[]
							;return this}
							;var p=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
							;for(var l=0;l<p.length;l++){i(u,p[l])}r.Revenue=u
							;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId","setLibrary","setTransport"]
							;function v(e){function t(t){e[t]=function(){
							e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
							for(var r=0;r<d.length;r++){t(d[r])}}v(r);r.getInstance=function(e){
							e=(!e||e.length===0?"$default_instance":e).toLowerCase()
							;if(!Object.prototype.hasOwnProperty.call(r._iq,e)){r._iq[e]={_q:[]};v(r._iq[e])
							}return r._iq[e]};e.amplitude=r})(window,document);
						`}
					</script>
					<script>{`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}</script>
					<script async src='https://r.wdfl.co/rw.js' data-rewardful='78d20a'></script>
				</Helmet>
				<StylesProvider injectFirst>
					<ThemeProvider theme={theme}>
						{showConsentPopup && <ConsentPopup />}
						<NavBar />
						<CssBaseline />
						{children}
						<Footer />
					</ThemeProvider>
				</StylesProvider>
			</React.Fragment>
		);
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({showConsentPopup: loadAnalyticsConsent()});
			loadAnalytics();
		}, 1000);
		
		loadPurchaseOptions((data) => {
			savePurchaseOptionsToStorage(data);
		});
	}
}

TopLayout.propTypes = {
	children: PropTypes.node,
};
