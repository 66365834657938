export default {
    CURRENCIES: {
        USD: 0,
        EUR: 1,
        GBP: 2
    },
    PERIOD_TYPES: {
        SINGLE_VIDEO: 0,
        MONTHLY: 1,
        YEARLY: 2
    },
    PLAN_TYPES: {
        HOBBYIST: 0,
        PRO: 1,
        UNLIMITED: 2,
        PAUSED: 3,
        FOURK: 4,
    },
    PURCHASE_OPTION_VIDEO_QUALITY: {
        FOUR_K_60FPS: 0,
        TEN_EIGHTY_P_60FPS: 1,
        SEVEN_TWENTY_P_30FPS: 2
    },

    PER_MONTH: '/ month',
    PER_YEAR: '/ year',
    PER_MO: '/mo',

    RESOLUTION_4K: '4K 60fps',
    RESOLUTION_1080P: '1080p 60fps',
    RESOLUTION_720P: '720p 30fps',

    WATERMARK_NO: 'No watermarks',
    WATERMARK_YES: 'Watermark on videos',

    UNLIMITED_DESCRIPTION: 'Perfect for record labels, DJ mixes, and album releases',
    PRO_DESCRIPTION: 'Professional quality videos for your music',
    
    FREE_NAME: 'Free',
    FREE_DESCRIPTION: 'HD videos with a watermark',
    FREE_PRICE: '0',

    PLAN_MOD_PURCHASE: 'p',
    PLAN_MOD_SUCCESS: 's',
    PLAN_PAGE_LOCATION: 'plan page',

    BILLED_YEARLY: 'billed yearly',
    UNLIMITED_STORAGE_FEATURE: 'Unlimited video storage',
    FREE_STORAGE_FEATURE: 'Videos expire in 10 days',

    HIGH_QUALITY_AUDIO_FEATURE: 'High quality audio',
    MEDIUM_QUALITY_AUDIO_FEATURE: 'Medium quality audio'
}

export function getDurationLimitString(duration) {
    return `${duration} minute videos`;
}