import React from 'react';
import inEU from '@segment/in-eu';
import { withStyles } from '@material-ui/core';
import ConsentDialog from './ConsentDialog';
import { AnalyticsConsentStatuses, setAnalyticsConsentStatus } from '../../../utilities/analyticsUtilities';

const styles = theme => ({
    popupContainer: {
        top: 64,
        right: 0,
        width: 300,
        zIndex: 999999,
        position: 'fixed',
        background: '#FFECB3',
        color: 'black',
    },
    descriptionText: {
        width: 250,
        fontSize: 12,
    },
    preferencesButton: {
        width: 250,
        fontSize: 12,
        border: 'none',
        background: 'none',
        textDecoration: 'underline',
        cursor: 'pointer',
        padding: 0
    },
    closeButton: {
        fontSize: 16,
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        position: 'absolute',
        top: '5px',
        fontWeight: '800'
    }
});

class ConsentPopup extends React.Component {
    state = {
        visible: true,
        dialogOpen: false
    }

    render() {
        const { classes } = this.props;
        const { dialogOpen, visible } = this.state;

        return (
            visible && <>
                {!dialogOpen && 
                    <div className={classes.popupContainer}>
                        <p className={classes.descriptionText}>
                            We use cookies (and other similar technologies) to collect data to improve your experience on our site. By clicking the X (close) button and using our website, you’re agreeing to the collection of data as described in our <a href="https://specterr.b-cdn.net/Specterr-Privacy-Policy.pdf" target="_blank">Privacy Policy</a>.
                        </p>
                        <button className={classes.preferencesButton} onClick={this.handlePreferences}>You can change your preferences at any time.</button>
                        <button className={classes.closeButton} onClick={this.handleClose}>X</button>
                    </div>
                }
                <ConsentDialog 
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                />
            </>
        );
    }

    handleClose = () => {
        setAnalyticsConsentStatus(AnalyticsConsentStatuses.OPTIN, inEU());
        this.setState({visible: false, dialogOpen: false});
    }

    handlePreferences = () => {
        this.setState({dialogOpen: true});
    }

    handleDialogClose = (preferencesSaved) => {
        this.setState({visible: !preferencesSaved, dialogOpen: false});
    }
}

export default withStyles(styles)(ConsentPopup);