import React from 'react';
import { withStyles, Container, Grid, Typography, IconButton } from '@material-ui/core';
import SolidBackdrop from './SolidBackdrop';
import MuiLink from '@material-ui/core/Link';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Link from './Link';
import theme from '../../theme';
import { trackEvent, AnalyticsConstants } from '../../../utilities/analyticsUtilities';
import { getConfigurationProperty } from '../../../configuration';
import { withSsr } from '../../../withSsr';

const styles = theme => ({
    section: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
        },
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(4)}px 0`
        },
        marginTop: 0,
        marginBottom: 0,
    },
});
class Footer extends React.Component {
	render() {
		const { classes, isSsr } = this.props;
        const supportLink = isSsr ? null : getConfigurationProperty("SupportEndpoint");

		return (
            <Container maxWidth='lg'>
				<Grid
                    container
                    alignItems='flex-start'
                    justify='flex-start'
                    spacing={10}
                    className={classes.section}
                >
                    <SolidBackdrop
                        color={theme.palette.background.default}
                        border
                    />
                    <Grid 
                        item 
                        md={4}
                        xs={12}
                        sm={6}
                    >
                        <Grid
                            container
                            alignItems='flex-start'
                            justify='center'
                            spacing={4}
                            direction='column'
                        >
                            <Grid item>
                                <Typography
                                    variant='h6'
                                    style={{textDecoration: 'underlined'}}
                                >
                                    Navigation
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link
                                    to='/'
                                    color='textSecondary'
                                >
                                    Home
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    to='/pricing/'
                                    color='textSecondary'
                                >
                                    Pricing
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    to='/music-visualizer/'
                                    color='textSecondary'
                                >
                                    Music Visualizer
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    to='/lyric-video-maker/'
                                    color='textSecondary'
                                >
                                    Lyric Video Maker
                                </Link>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid 
                        item 
                        md={4}
                        xs={12}
                        sm={6}
                    >
                        <Grid
                            container
                            alignItems='flex-start'
                            justify='center'
                            spacing={4}
                            direction='column'
                        >
                            <Grid item>
                                <Typography
                                    variant='h6'
                                    style={{textDecoration: 'underlined'}}
                                >
                                    Company
                                </Typography>
                            </Grid>
                            <Grid item>
                                <MuiLink
                                    href='https://specterr.com/blog'
                                    color='textSecondary'
                                >
                                    Blog
                                </MuiLink>
                            </Grid>
                            <Grid item>
                                <MuiLink
                                    href={supportLink}
                                    color='textSecondary'
                                    target='_blank'
                                    onClick={() => {
                                        trackEvent('Clicked Footer Link', {
                                            name: 'Support',
                                            category: AnalyticsConstants.MISC_CATEGORY
                                        });
                                    }}
                                >
                                    Support
                                </MuiLink>
                            </Grid>
                            <Grid item>
                                <MuiLink
                                    href='https://specterr.getrewardful.com/signup'
                                    color='textSecondary'
                                    target='_blank'
                                    onClick={() => {
                                        trackEvent('Clicked Footer Link', {
                                            name: 'Affiliate Program',
                                            category: AnalyticsConstants.MISC_CATEGORY
                                        });
                                    }}
                                >
                                    Affiliate Program
                                </MuiLink>
                            </Grid>
                            <Grid item>
                                <MuiLink
                                    href='https://specterr.com/Specterr-Terms-of-Service.pdf'
                                    color='textSecondary'
                                    target='_blank'
                                    onClick={() => {
                                        trackEvent('Clicked Footer Link', {
                                            name: 'Terms of Service',
                                            category: AnalyticsConstants.MISC_CATEGORY
                                        });
                                    }}
                                >
                                    Terms of Service
                                </MuiLink>
                            </Grid>
                            <Grid item>
                                <MuiLink
                                    href='https://specterr.com/Specterr-Privacy-Policy.pdf'
                                    color='textSecondary'
                                    target='_blank'
                                    onClick={() => {
                                        trackEvent('Clicked Footer Link', {
                                            name: 'Privacy Policy',
                                            category: AnalyticsConstants.MISC_CATEGORY
                                        });
                                    }}
                                >
                                    Privacy Policy
                                </MuiLink>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid 
                        item 
                        md={4}
                        xs={12}
                        sm={6}
                    >
                        <Grid
                            container
                            alignItems='flex-start'
                            justify='center'
                            spacing={4}
                            direction='column'
                        >
                            <Grid item>
                                <Typography
                                    variant='h6'
                                    style={{textDecoration: 'underlined'}}
                                >
                                    Connect
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant='body2'
                                    color='textSecondary'
                                >
                                    Contact: info@specterr.com
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    href='https://www.youtube.com/channel/UCRIe-r2RsY3BgjIq9T8rXDA'
                                    target='_blank'
                                    aria-label='Go to Specterr YouTube channel'
                                    onClick={() => {
                                        trackEvent('Clicked Social Link', {
                                            name: 'YouTube',
                                            category: AnalyticsConstants.MISC_CATEGORY
                                        });
                                    }}
                                >
                                    <YouTubeIcon />
                                </IconButton>
                                <IconButton
                                    href='https://www.instagram.com/specterrapp/'
                                    target='_blank'
                                    aria-label='Go to Specterr Instagram'
                                    onClick={() => {
                                        trackEvent('Clicked Social Link', {
                                            name: 'Instagram',
                                            category: AnalyticsConstants.MISC_CATEGORY
                                        });
                                    }}
                                >
                                    <InstagramIcon />
                                </IconButton>
                                <IconButton
                                    href='https://www.facebook.com/specterr/'
                                    target='_blank'
                                    aria-label='Go to Specterr Facebook'
                                    onClick={() => {
                                        trackEvent('Clicked Social Link', {
                                            name: 'Facebook',
                                            category: AnalyticsConstants.MISC_CATEGORY
                                        });
                                    }}
                                >
                                    <FacebookIcon />
                                </IconButton>
                                <IconButton
                                    href='https://twitter.com/specterrapp'
                                    target='_blank'
                                    aria-label='Go to Specterr Twitter'
                                    onClick={() => {
                                        trackEvent('Clicked Social Link', {
                                            name: 'Twitter',
                                            category: AnalyticsConstants.MISC_CATEGORY
                                        });
                                    }}
                                >
                                    <TwitterIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant='caption'
                                    color='textSecondary'
                                >
                                    © {new Date().getFullYear()} Tunebat LLC
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
			</Container>
		);
	}
}

export default withSsr(withStyles(styles)(Footer));